export function handleReadMore() {
   const content = $(".blog-detail-post .content");
   const readMore = $(".blog-detail-post .readmore-btn");
 
   const maxHeight = 200;
 
   if (content.prop("scrollHeight") > maxHeight) {
     readMore.addClass("show");
   }
 
   readMore.on("click", function () {
     content.toggleClass("expanded");
     readMore.remove();
   });
 }
 