function scrollToTop(speed = "fast") {
   $("html, body").animate(
       {
           scrollTop: 0,
       },
       speed
   );
}

function setupBackToTopButton(selector) {
   const backToTopButton = $(selector);
   backToTopButton.click(function (event) {
       event.preventDefault();
       scrollToTop();
   });
}

export { setupBackToTopButton };
