export function loadMore(options) {
  const settings = $.extend(
    {
      buttonSelector: "",
      templateId: "",
      itemsToLoad: 4,
      targetContainer: "",
      paneSelector: null, // Pane selector là tùy chọn, nếu không có pane thì để null
    },
    options
  );

  const template = $(`#${settings.templateId}`).html();

  $(settings.buttonSelector).on("click", function (e) {
    e.preventDefault();
    const $button = $(this);
    let $container;

    if (settings.paneSelector) {
      // Nếu có pane, tìm container dựa trên pane active
      const $pane = $button.closest(settings.paneSelector);
      if ($pane.hasClass("active")) {
        $container = $pane.find(".row");
      }
    } else {
      // Nếu không có pane, tìm container trực tiếp
      $container = $button.closest(settings.targetContainer).find(".row");
    }

    if ($container) {
      for (let i = 0; i < settings.itemsToLoad; i++) {
        $container.append(template);
      }
    }
  });
}
