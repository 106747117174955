export function toggleSubmenuMobile() {
   $(".menu-mobile-nav > li > a").click(function (e) {
     var $submenu = $(this).next(".submenu");
     if ($submenu.length > 0) {
       e.preventDefault();
       if ($submenu.is(":visible")) {
         $submenu.slideUp();
       } else {
         $(".submenu").slideUp();
         $submenu.slideDown();
       }
     }
   });
 }
 