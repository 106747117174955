export function datePicker(selector) {
  $(function () {
    // Set default start and end dates
    var start = moment().startOf("day");
    var end = moment().endOf("day");

    // Function to initialize the Date Range Picker
    function initializeDateRangePicker(element, singleCalendar, opens) {
      element.daterangepicker(
        {
          opens: "left",
          drops: "down", // Set drops value based on screen width
          autoUpdateInput: false, // Prevent default input update
          startDate: start,
          endDate: end,
          singleCalendar: singleCalendar, // Set singleCalendar based on the screen width
          locale: {
            format: "MM-DD-YYYY",
            cancelLabel: "cancel", // Optional: Add a clear button
          },
        },
        function (start, end, label) {
          // Update the input text with selected date range
          element.val(
            start.format("MM-DD-YYYY") + " - " + end.format("MM-DD-YYYY")
          );
        }
      );
    }

    // Function to check screen width and initialize the Date Range Picker accordingly
    function checkScreenWidth() {
      var singleCalendar = $(window).width() <= 599;
      var opens = singleCalendar ? "left" : "center";

      $(selector).each(function () {
        initializeDateRangePicker($(this), singleCalendar, opens);
      });
    }

    // Initial check
    checkScreenWidth();

    // Reinitialize Date Range Picker on window resize
    $(window).resize(function () {
      checkScreenWidth();
    });

    // Optional: Clear the date range selection and reset the input text
    $(selector).on("cancel.daterangepicker", function (ev, picker) {
      $(this).val("Ngày đến - Ngày đi");
    });
  });
}
