import { toggleSubmenuMobile } from "./modules/mobileMenu/submenuMobile.js";
import { setupBackToTopButton } from "./modules/homePage/backToTop.js";
import { initScrollManager } from "./modules/homePage/scrollManager.js";
import setupTabClicks from "./modules/common/tabFunctionality.js";
import { setupRoomQuantityHandlers } from "./modules/homePage/RoomQuantityModule.js";
import { setupDropdownHandlers } from "./modules/homePage/DropdownModule.js";
import { DatePickerFilter } from "./modules/homePage/DatePickerModule.js";
import setupFilterTabs from "./modules/homePage/FilterTabModule.js";
import setupMobileMenu from "./modules/homePage/MobileMenuModule.js";
import setupHotelInfoModal from "./modules/homePage/HotelInfoModule.js";
import {
  bannerCarouselConfig,
  systemsCarouselConfig,
  travelCarouselConfig,
  system4CarouselConfig,
  tabListCarouselConfig,
  categoryGuideCarouselConfig,
  cardGuideCarouselConfig,
  topGuideCarouselConfig,
  detailMomentCarouselConfig,
  topDestinationsConfig,
  cardTourCarouselConfig,
} from "./modules/common/carouselConfig.js";
import setupForm from "./modules/homePage/setupForm.js";

// Common Module
import { datePicker } from "./modules/common/datePicker.js";
import { timePicker } from "./modules/common/timePicker.js";
import { updateRangeValue } from "./modules/common/rangeFilter.js";
import { initFilterToggle } from "./modules/common/filterToggle.js";
import { loadMore } from "./modules/common/loadMore.js";

// Tour Guide Page Module
import { TourGuideFilter } from "./modules/tourGuidePage/tourGuideFilter.js";
import { initializeCarouselControls } from "./modules/tourGuidePage/carouselControls.js";
import { setupRoomCount } from "./modules/tourGuidePage/roomCountModule.js";
import { dropdownRoom } from "./modules/tourGuidePage/dropdownRoom.js";
import { setupPaymentToggle } from "./modules/tourGuidePage/payment.js";

// Post Moment Page Module
import { dropdownLocation } from "./modules/momentPage/momentDropdown.js";

// Search Page Module
import { initDropdown } from "./modules/searchPage/dropdown.js";
import { initToggleMenu } from "./modules/searchPage/toggleMenu.js";

// View Map Page Module
import { initFilterReset } from "./modules/viewMap/filterReset.js";

// List Hotel Detail
import { initFancyBox } from "./modules/listHotelDetail/fancyboxSetup.js";

// Payment Book Room
import { removeCheckedCheckboxes } from "./modules/paymentBookRoom/checkboxModule.js";

// List Event Page
import { loadMoreItems } from "./modules/listEventPage/loadMoreItems.js";

// Tour Detail Page
import { setupServicesControl } from "./modules/tourDetailPage/servicesModule.js";

// Location Detail Page
import { setupReadMore } from "./modules/locationDetailPage/readMoreModule.js";

// Blog Page
import { handleReadMore } from "./modules/blogPage/readmore.js";

$(document).ready(function () {
  // Chức năng toggle submenu mobile
  toggleSubmenuMobile();

  //Chức năng click button back top
  setupBackToTopButton("#back-to-top");

  // Chức năng khi scroll trang, show header và button back top
  initScrollManager();

  // Slide Banner
  $(".banner-carousel").owlCarousel(bannerCarouselConfig);

  // Slide danh sách tab
  $(".tab-list").owlCarousel(tabListCarouselConfig);

  // Slide TOUR NỔI BẬT, SỰ KIỆN NỔI BẬT, KHÁCH SẠN NỔI BẬT
  $(".tour-carousel, .event-carousel, .hotel-carousel").owlCarousel(
    systemsCarouselConfig
  );

  // Slide BẠN MUỐN ĐI CHƠI Ở ĐÂU ?
  $(".travel-carousel").owlCarousel(travelCarouselConfig);

  // Slide HƯỚNG DẪN VIÊN NỔI BẬT
  $(".guide-carousel").owlCarousel(system4CarouselConfig);

  // Chức năng FilterTabs
  setupFilterTabs();

  // Chức năng chọn số lượng phòng
  setupRoomQuantityHandlers();

  // Chức năng Dropdown địa điểm
  setupDropdownHandlers();

  // Cấu hình DatePicker
  DatePickerFilter(".daterange-dichVu");
  DatePickerFilter(".daterange-tourDuLich");
  DatePickerFilter(".daterange-suKien");

  // Chức năng chuyển tab các danh mục: TOUR NỔI BẬT, SỰ KIỆN NỔI BẬT, KHÁCH SẠN NỔI BẬT, HƯỚNG DẪN VIÊN NỔI BẬT, BLOG
  setupTabClicks(".tab-list-item", ".tab-pane", "#system1", false);
  setupTabClicks(".tab-list-item", ".tab-pane", "#system2", false);
  setupTabClicks(".tab-list-item", ".tab-pane", "#system3", false);
  setupTabClicks(".tab-list-item", ".tab-pane", "#system4", false);
  setupTabClicks(".blog-tab-item", ".blog-pane", "#blog", false);

  // SetupMobileMenu
  setupMobileMenu();

  // Chức năng show thông tin hotel
  setupHotelInfoModal();

  // Chức năng form login, Register
  setupForm();

  // ============== Tour Guide Page ============== //
  // Chức năng dropdown filter seach
  TourGuideFilter();

  // Slide NHỮNG HƯỚNG DẪN VIÊN CHUYÊN NGHIỆP CỦA CHÚNG TÔI
  $(".category-guide .owl-carousel").owlCarousel(categoryGuideCarouselConfig);

  // Chức năng điều khiển carousel
  initializeCarouselControls();

  // Chức năng loadmore xem thêm
  loadMore({
    // Selector cho nút "Xem thêm"
    buttonSelector: ".readmore-button",

    // ID của template chứa nội dung các mục mới
    templateId: "item-template-weGuide",

    // Số lượng mục cần tải thêm mỗi lần nhấn nút
    itemsToLoad: 4,

    // Selector cho container mục tiêu chứa các mục mới
    targetContainer: "#we-guide",

    // Pane selector là tùy chọn, nếu có pane thì được null
    paneSelector: ".tab-pane",
  });

  // ============== Tour Guide Detail Page ============== //
  // Chức năng chuyển tab top-guide
  setupTabClicks(".tab-list-item", ".tab-pane", "#we-guide");

  // Chức năng chuyển tab top-guide-list
  setupTabClicks(".top-guide-tab", ".top-guide-pane", "#tab-guide");

  $(".carousel-card-guide").owlCarousel(cardGuideCarouselConfig);

  $(".top-guide-carousel").owlCarousel(topGuideCarouselConfig);

  // Chức năng show modal chat với hướng dẫn viện
  $(".info-guide-chat").on("click", function () {
    $("#guideChatModal").modal("show");
  });

  // Chức năng chọn lịch
  datePicker("#daterange-guide");

  // Chức năng chọn thời gian
  timePicker("#timepicker-guide");

  // Chức năng chọn phòng
  dropdownRoom("#dropdown-guide-selectRoom", ".dropdown-guide-room");

  // Chức năng chọn số lượng phòng
  setupRoomCount();

  // ============== Tour Guide Payment ============== //
  // Chức năng chọn phương thức thanh toán
  setupPaymentToggle();

  // ============== Post Moment ============== //
  // Chức năng dropdown địa điểm
  dropdownLocation();

  // Chức năng slide bài viết liên quan
  $(".detail-moment-related-list").owlCarousel(detailMomentCarouselConfig);

  // ============== Search Page, View Map Page ============== //
  // Chức năng thanh lọc giá
  updateRangeValue("priceRangeSearchPage", "currentValueSearchPage");

  // Chức năng toggle menu
  initToggleMenu();

  // Chức năng dropdown filter seach
  initDropdown();

  // Chức năng thanh Lọc giá
  updateRangeValue("priceRangeViewMap", "currentValueViewMap");

  // Chức năng show filter dưới mobile
  initFilterToggle(
    "#filter-mobile-searchPage",
    "#search-page-filterMobile",
    ".overlay-searchPage"
  );

  // Chức năng reset filter
  initFilterReset();

  // Chức năng loadmore item xem thêm
  loadMore({
    // Selector cho nút "Xem thêm"
    buttonSelector: ".readmore-button",

    // ID của template chứa nội dung các mục mới
    templateId: "item-template-viewMap",

    // Số lượng mục cần tải thêm mỗi lần nhấn nút
    itemsToLoad: 4,

    // Selector cho container mục tiêu chứa các mục mới
    targetContainer: "#view-map-find",
  });

  // ============== Hotel List Page ============== //
  // Chức năng chuyển tab danh mục
  setupTabClicks(
    ".hotel-list-resortTab-item",
    ".hotel-list-resortPane",
    "#hotel-list-resort",
    true
  );

  // Chức năng Slide CÁC ĐIỂM LƯU TRÚ HÀNG ĐẦU
  $(".top-destinations-list").owlCarousel(topDestinationsConfig);

  // Chức năng show filter dưới mobile
  initFilterToggle(
    ".services-detail-buttonMobile",
    ".services-detail",
    ".overlay-servicesDetail"
  );

  // Initialize FancyBox
  initFancyBox();

  // Chức năng đặt ngày nhận phòng và trả phòng
  DatePickerFilter(".list-hotelDetail-searchRoom-date");

  // ============== Payment Book Room ============== //
  // Chức năng xóa checkbox
  removeCheckedCheckboxes();

  // Gallery FancyBox
  Fancybox.bind('[data-fancybox="gallery"]', {
    Thumbs: {
      type: "classic",
    },
  });

  // ============== Tour Detail Page ============== //
  // Date Range Picker
  DatePickerFilter("#daterange-tourDetail");

  // Click active date
  $(".tour-detail-calendar-item").click(function () {
    $(".tour-detail-calendar-item").removeClass("active");
    $(this).addClass("active");
  });

  // Chức năng chọn gói dịch vụ
  setupServicesControl();

  // Chức năng xem thêm, thu gọn
  setupReadMore(720); // thay đổi số ký tự nếu cần

  // ============== List Tour Page ============== //
  // Chức năng loadmore xem thêm
  loadMore({
    // Selector cho nút "Xem thêm"
    buttonSelector: ".readmore-button",

    // ID của template chứa nội dung các mục mới
    templateId: "item-template-tour",

    // Số lượng mục cần tải thêm mỗi lần nhấn nút
    itemsToLoad: 4,

    // Selector cho container mục tiêu chứa các mục mới
    targetContainer: "#system1",

    // Pane selector là tùy chọn, nếu có pane thì được null
    paneSelector: ".tab-pane",
  });

  // ============== List Event Page ============== //
  // Chức năng loadmore xem thêm
  loadMoreItems();

  // ============== List Blog Page, BLog Detail Page ============== //
  // Chức năng loadmore Xem thêm, List BLog
  loadMore({
    // Selector cho nút "Xem thêm"
    buttonSelector: ".readmore-button",

    // ID của template chứa nội dung các mục mới
    templateId: "item-template-blogArticles",

    // Số lượng mục cần tải thêm mỗi lần nhấn nút
    itemsToLoad: 3,

    // Selector cho container mục tiêu chứa các mục mới
    targetContainer: ".list-blog-articles",
  });

  // Chức năng loadmore Xem thêm, Blog Detail
  loadMore({
    // Selector cho nút "Xem thêm"
    buttonSelector: ".readmore-button",

    // ID của template chứa nội dung các mục mới
    templateId: "item-template-locationBlogDetail",

    // Số lượng mục cần tải thêm mỗi lần nhấn nút
    itemsToLoad: 3,

    // Selector cho container mục tiêu chứa các mục mới
    targetContainer: ".blog-detail-location",

    paneSelector: ".post-pane",
  });

  // Chức năng loadmore Xem thêm, Blog Detail
  loadMore({
    // Selector cho nút "Xem thêm"
    buttonSelector: ".readmore-button",

    // ID của template chứa nội dung các mục mới
    templateId: "item-template-blogLocation",

    // Số lượng mục cần tải thêm mỗi lần nhấn nút
    itemsToLoad: 3,

    // Selector cho container mục tiêu chứa các mục mới
    targetContainer: ".list-blog-location",
  });

  // Chức năng readmore bài viết
  handleReadMore();

  // Chức năng slide Tour NỔI BẬT
  $("#tour-noi-bat .owl-carousel").owlCarousel(cardTourCarouselConfig);

  // Chức năng chuyển tab danh mục
  setupTabClicks(".post-tabs-item", ".post-pane", "#post-location", false);

  // Chức năng loadmore item xem thêm
  loadMore({
    // Selector cho nút "Xem thêm"
    buttonSelector: ".readmore-button",

    // ID của template chứa nội dung các mục mới
    templateId: "item-template-listHotel",

    // Số lượng mục cần tải thêm mỗi lần nhấn nút
    itemsToLoad: 4,

    // Selector cho container mục tiêu chứa các mục mới
    targetContainer: "#hotel-list-resort",

    // Pane selector là tùy chọn, nếu có pane thì được null
    paneSelector: ".hotel-list-resortPane",
  });

  // Reload Owl Carousel
  $(".owl-carousel").owlCarousel(tabListCarouselConfig);

  // Tính toán lại chiều rộng của owl-stage sau khi khởi tạo
  const recalculateOwlStageWidth = () => {
    const $owlStage = $(".owl-carousel .owl-stage");
    let totalWidth = 0;

    $owlStage.children().each(function () {
      totalWidth += $(this).outerWidth(true);
    });

    $owlStage.width(totalWidth);
  };

  // Recalculate on init and on window resize
  recalculateOwlStageWidth();
  $(window).resize(recalculateOwlStageWidth);
});
