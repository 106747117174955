function setupForm() {
  // Form Register and Login
  var $body = $("body");
  var $overlayForm = $(".overlay-form");
  var $form = $(".form");
  var $formButtonClose = $(".form-header button");
  var $loginForm = $("#form-login");
  var $registerForm = $("#form-register");
  var $formMessage = $(".form-message");
  var $formField = $(".form-field");
  var $formFieldInput = $(".form-field input");
  var $menuMobile = $(".menu-mobile");
  var $loginBtn = $(".login-btn");
  var $registerBtn = $(".register-btn");
  var $overlayMenu = $(".overlay-menu")

  // Function to handle hiding the form
  function handleHideForm() {
    $form.removeClass("show");
    $loginForm.add($registerForm).removeAttr("style");
    $formMessage.css("display", "none");
    $formField.css("border-color", "");
    $formFieldInput.val("");

    $body.removeClass("no-scroll");
    $overlayForm.fadeOut(function () {
      $(this).off("click");
    });
  }

  $(".toggle-login").click(function () {
    toggleForms();
  });

  $(".toggle-register").click(function () {
    toggleForms();
  });

  // Function to toggle forms visibility
  function toggleForms() {
    $loginForm.add($registerForm).toggle("d-none");
    $overlayForm.addClass("disabled-click");
    setTimeout(function () {
      $overlayForm.removeClass("disabled-click");
    }, 1000);
  }

  // Function to show a specific form
  function showForm(hideFormSelector) {
    if ($menuMobile.hasClass("active")) {
      $($menuMobile).removeClass("active");
      $($overlayMenu).fadeOut();
    }

    $body.addClass("no-scroll");
    $overlayForm.fadeIn().addClass("disabled-click");

    $form
      .addClass("show")
      .click(function (e) {
        e.stopPropagation();
      })
      .one("animationend", function () {
        $($overlayForm)
          .removeClass("disabled-click")
          .one("click", handleHideForm);
        $formButtonClose.one("click", handleHideForm);
      });

    $(hideFormSelector).hide();
  }

  // Click handlers for login and register buttons
  $loginBtn.click(function () {
    showForm("#form-register");
  });
  $registerBtn.click(function () {
    showForm("#form-login");
  });

  // Click handler for "Thông tin"
  $(".card-detail").on("click", function () {
    const $infoHotel = $(".info-hotel");
    const $overlay = $(".overlay-hotel");
    const $body = $("body");

    $body.addClass("no-scroll");
    $infoHotel.addClass("show");
    $overlay.addClass("disabled-click");

    $infoHotel.click(function (event) {
      event.stopPropagation();
    });

    setTimeout(() => {
      $overlay.removeClass("disabled-click");
    }, 640);

    $overlay.fadeIn().click(function () {
      $(this).fadeOut();
      $infoHotel.removeClass("show");
      $body.removeClass("no-scroll");
    });
  });

  // Xác nhận form đăng nhập
  $("#form-login form").validate({
    rules: {
      email: {
        required: true,
        email: true,
      },
      password: {
        required: true,
        minlength: 5,
      },
    },
    messages: {
      email: {
        required: "Vui lòng nhập địa chỉ email",
        email: "Vui lòng nhập một địa chỉ email hợp lệ",
      },
      password: {
        required: "Vui lòng nhập mật khẩu",
        minlength: "Mật khẩu của bạn phải có ít nhất 5 ký tự",
      },
    },
    errorPlacement: function (error, element) {
      var errorMessageText = error.text();
      element
        .closest(".form-group")
        .find(".form-message")
        .text(errorMessageText);
    },
    highlight: function (element, errorClass) {
      $(element)
        .closest(".form-group")
        .find(".form-message")
        .css("display", "block")
        .closest(".form-group")
        .find(".form-field")
        .css("border-color", "red");
    },
    unhighlight: function (element, errorClass) {
      $(element)
        .closest(".form-group")
        .find(".form-message")
        .css("display", "none")
        .closest(".form-group")
        .find(".form-field")
        .css("border-color", "");
    },
  });

  // Xác nhận form đăng ký
  $("#form-register form").validate({
    rules: {
      firstName: {
        required: true,
      },
      lastName: {
        required: true,
      },
      phone: {
        required: true,
        number: true,
      },
      email: {
        required: true,
        email: true,
      },
      password: {
        required: true,
        minlength: 5,
      },
      terms: {
        required: true,
      },
    },
    messages: {
      firstName: "Vui lòng nhập họ",
      lastName: "Vui lòng nhập tên đệm",
      phone: {
        required: "Vui lòng nhập số điện thoại",
        number: "Vui lòng nhập số hợp lệ",
      },
      email: {
        required: "Vui lòng nhập địa chỉ email",
        email: "Vui lòng nhập một địa chỉ email hợp lệ",
      },
      password: {
        required: "Vui lòng nhập mật khẩu",
        minlength: "Mật khẩu của bạn phải có ít nhất 5 ký tự",
      },
      terms: "Bạn phải đồng ý với các điều khoản",
    },
    errorPlacement: function (error, element) {
      var errorMessageText = error.text();
      element
        .closest(".form-group, .form-check")
        .find(".form-message")
        .text(errorMessageText);
    },
    highlight: function (element, errorClass) {
      $(element)
        .closest(".form-group, .form-check")
        .find(".form-message")
        .css("display", "block")
        .closest(".form-group")
        .find(".form-field")
        .css("border-color", "red");
    },
    unhighlight: function (element, errorClass) {
      $(element)
        .closest(".form-group, .form-check")
        .find(".form-message")
        .css("display", "none")
        .closest(".form-group")
        .find(".form-field")
        .css("border-color", "");
    },
  });
}

export default setupForm;
