export function initFilterToggle(triggerSelector, filterSelector, overlaySelector) {
  $(triggerSelector).click(function (event) {
    $("body").addClass("no-scroll");
    event.stopPropagation();
    $(filterSelector).toggleClass("show");
    $(overlaySelector)
      .fadeIn()
      .click(function () {
        $("body").removeClass("no-scroll");
        $(filterSelector).removeClass("show");
        $(overlaySelector).fadeOut();
      });
  });
}
