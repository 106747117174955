export function updateRangeValue(rangeElementId, valueElementId) {
  $("#" + rangeElementId).on("input", function () {
    var currentValue = $(this).val();
    $("#" + valueElementId).text(Number(currentValue).toLocaleString("vi-VN"));
  });
}

// Tự động gọi hàm này cho các phần tử có id tương ứng
$("[data-range-filter]").each(function () {
  var rangeElementId = $(this).attr("id");
  var valueElementId = $(this).data("valueElementId");
  updateRangeValue(rangeElementId, valueElementId);
});
