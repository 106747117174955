export function initializeCarouselControls() {
   $(".category-guide .prevBtn").click(function () {
      $(this)
        .closest(".category-guide")
        .find(".owl-carousel")
        .trigger("prev.owl.carousel");
    });
    $(".category-guide .nextBtn").click(function () {
      $(this)
        .closest(".category-guide")
        .find(".owl-carousel")
        .trigger("next.owl.carousel");
    });
 }
 