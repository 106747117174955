export function loadMoreItems() {
   var itemsToLoad = 4; // Số lượng mục muốn tải thêm mỗi lần
 
   $(".readmore-button").click(function (e) {
     e.preventDefault();
 
     // Lấy tab-pane đang active
     var activePane = $("#system1 .tab-pane.active .event-grid");
 
     // Lấy HTML mẫu cho các mục mới
     var template = $("#item-template-eventCard").html();
 
     // Append số lượng itemsToLoad vào tab-pane đang active
     for (var i = 0; i < itemsToLoad; i++) {
       activePane.append(template);
     }
   });
 }
 