export function initToggleMenu() {
  $(".search-col-header").on("click", function () {
    var content = $(this).next(".search-col-content");
    content.toggle();

    var icon = $(this).find(".search-col-icon");
    if (content.is(":visible")) {
      icon.attr("src", "./assets/images/minus.svg");
    } else {
      icon.attr("src", "./assets/images/plus.svg");
    }
  });
}
