function setupHotelInfoModal() {
  $("#hotel-list-resort").on("click", ".card-detail", function () {
    const $infoHotel = $(".info-hotel");
    const $overlay = $(".overlay-hotel");
    const $body = $("body");

    $body.addClass("no-scroll");
    $infoHotel.addClass("show");
    $overlay.addClass("disabled-click");

    $infoHotel.click(function (event) {
      event.stopPropagation();
    });

    // Allow interaction after the animation
    setTimeout(() => {
      $overlay.removeClass("disabled-click");
    }, 640);

    // Setup fade in and on click fade out for overlay
    $overlay.fadeIn().click(function () {
      $(this).fadeOut();
      $infoHotel.removeClass("show");
      $body.removeClass("no-scroll");
    });
  });
}

export default setupHotelInfoModal;
