export function DatePickerFilter(selector) {
  $(function () {
    var start = moment().startOf("day");
    var end = moment().endOf("day");

    function initializeDateRangePicker($element, singleCalendar, opens) {
      $element.daterangepicker(
        {
          opens: opens,
          drops: "top", 
          autoUpdateInput: false,
          startDate: start,
          endDate: end,
          singleDatePicker: singleCalendar,
          locale: {
            format: "MM-DD-YYYY",
            cancelLabel: "Cancel",
          },
        },
        function (start, end, label) {
          $element.val(
            start.format("MM-DD-YYYY") + " - " + end.format("MM-DD-YYYY")
          );
        }
      );
    }

    // Hàm kiểm tra độ rộng màn hình và khởi tạo Date Range Picker tương ứng
    function checkScreenWidth() {
      var singleCalendar = $(window).width() <= 599;
      var opens = singleCalendar ? "left" : "center";

      $(selector).each(function () {
        $(this).data('daterangepicker')?.remove();
        initializeDateRangePicker($(this), singleCalendar, opens);
      });
    }

    // Kiểm tra ban đầu
    checkScreenWidth();

    // Khởi tạo lại Date Range Picker khi thay đổi kích thước cửa sổ
    $(window).resize(function () {
      checkScreenWidth();
    });

    // Loại bỏ sự kiện click từ inputs
    $(selector).off("click.daterangepicker");

    // Hiển thị Date Range Picker khi click vào toàn bộ phần tử .filter-select--date
    $(document).on("click", ".filter-select--date", function () {
      var $input = $(this).find(selector);
      if (!$input.is(":focus")) {
        $input.focus();
        if ($input.data('daterangepicker')) {
          $input.data('daterangepicker').show();
        }
      }
    });

    // Tuỳ chọn: Xoá lựa chọn khoảng ngày và đặt lại văn bản input
    $(document).on("cancel.daterangepicker", selector, function (ev, picker) {
      $(this).val("Ngày đến - Ngày đi");
    });
  });
}