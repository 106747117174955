export function timePicker(selector, options = {}) {
   // Thiết lập các giá trị mặc định cho timepicker
   const defaultOptions = {
     interval: 60,
     minTime: "10",
     maxTime: "6:00pm",
     timeFormat: "HH:mm:ss",
     defaultTime: false,
     startTime: "10:00",
     dynamic: false,
     dropdown: true,
     scrollbar: true,
   };
 
   // Hợp nhất các tùy chọn người dùng với các giá trị mặc định
   const finalOptions = { ...defaultOptions, ...options };
 
   // Khởi tạo timepicker với selector và tùy chọn cuối cùng
   $(selector).timepicker(finalOptions);
 }
 