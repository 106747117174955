export function setupReadMore(noOfCharac = 720) {
   let contents = $(".location-detail-map .content");
 
   contents.each(function () {
     let content = $(this);
     let originalHTML = content.html();
 
     if (content.text().length < noOfCharac) {
       content.next().hide();
     } else {
       let displayText = originalHTML.slice(
         0,
         originalHTML.indexOf(content.text().slice(0, noOfCharac)) + noOfCharac
       );
       let moreText = originalHTML.slice(
         originalHTML.indexOf(content.text().slice(0, noOfCharac)) + noOfCharac
       );
       content.html(
         `${displayText}<span class="dots">...</span><span style="display: none" class="more">${moreText}</span>`
       );
     }
   });
 
   $(".location-detail-map .readmore-btn").on("click", function () {
     let btn = $(this);
     let post = btn.parent();
     post.find(".dots").toggle();
     post.find(".more").toggle();
     btn.text() === "Xem thêm" ? btn.text("Thu gọn") : btn.text("Xem thêm");
   });
 }
 