export function dropdownLocation() {
   // Show/hide dropdown on click
   $("#search-moment-dropdown").on("click", function () {
       $(".search-moment-dropdown-list").toggle();
   });

   // Set value on list item click
   $(".search-moment-dropdown-list ul li").on("click", function () {
       var selectedValue = $(this).text();
       $("#search-moment-value").text(selectedValue);
       $(".search-moment-dropdown-list").hide();
   });

   // Hide dropdown if clicked outside
   $(document).on("click", function (event) {
       if (!$(event.target).closest(".search-moment-dropdown").length) {
           $(".search-moment-dropdown-list").hide();
       }
   });
}
