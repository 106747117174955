export const bannerCarouselConfig = {
  loop: true,
  autoplay: true,
  smartSpeed: 850,
  margin: 10,
  nav: false,
  dots: false,
  autoplayHoverPause: true,
  autoplayTimeout: 4500,
  responsive: {
    0: {
      items: 1,
    },
  },
};

export const systemsCarouselConfig = {
  loop: true,
  autoplay: true,
  smartSpeed: 850,
  autoplayHoverPause: true,
  autoplayTimeout: 4500,
  margin: 20,
  navText: [
    '<i class="fa-solid fa-chevron-left"></i>',
    '<i class="fa-solid fa-chevron-right"></i>',
  ],
  responsive: {
    0: {
      margin: 15,
      items: 1,
    },
    600: {
      items: 2,
    },
    1024: {
      item: 3,
    },
    1280: {
      items: 4,
      nav: true,
      dots: false,
    },
  },
};

export const system4CarouselConfig = {
  loop: true,
  autoplay: true,
  smartSpeed: 850,
  autoplayHoverPause: true,
  autoplayTimeout: 8000,
  margin: 20,
  navText: [
    '<i class="fa-solid fa-chevron-left"></i>',
    '<i class="fa-solid fa-chevron-right"></i>',
  ],
  responsive: {
    0: {
      items: 1,
      margin: 14,
    },
    600: {
      items: 2,
    },
    1024: {
      items: 3,
    },
    1280: {
      nav: true,
      dots: false,
    },
  },
};

export const travelCarouselConfig = {
  loop: true,
  autoplay: true,
  smartSpeed: 850,
  margin: 20,
  autoplayHoverPause: true,
  autoplayTimeout: 6000,
  navText: [
    '<i class="fa-solid fa-chevron-left"></i>',
    '<i class="fa-solid fa-chevron-right"></i>',
  ],
  responsive: {
    0: {
      items: 2,
      margin: 14,
    },
    600: {
      items: 4,
    },
    1280: {
      items: 6,
      nav: true,
      dots: false,
    },
  },
};

export const tabListCarouselConfig = {
  dots: false,
  navText: [
    '<i class="fa-solid fa-chevron-left"></i>',
    '<i class="fa-solid fa-chevron-right"></i>',
  ],
  autoWidth: true,
  mouseDrag: false,
  responsive: {
    768: {
      nav: true,
    },
  },
};

export const categoryGuideCarouselConfig = {
  autoplay: true,
  autoplayHoverPause: true,
  autoplayTimeout: 4500,
  loop: true,
  smartSpeed: 640,
  margin: 20,
  nav: false,
  responsive: {
    0: { items: 1, dots: true },
    576: { items: 2, dots: false },
    1000: { items: 3, dots: false },
  },
};

export const cardGuideCarouselConfig = {
  loop: true,
  autoplay: true,
  smartSpeed: 850,
  margin: 10,
  autoplayHoverPause: true,
  autoplayTimeout: 4000,
  dots: true,
  items: 1,
};

export const cardTourCarouselConfig = {
  loop: true,
  autoplay: true,
  smartSpeed: 850,
  margin: 10,
  autoplayHoverPause: true,
  autoplayTimeout: 4000,
  navText: [
    '<i class="fa-solid fa-chevron-left"></i>',
    '<i class="fa-solid fa-chevron-right"></i>',
  ],
  dots: true,
  items: 1,
  responsive: {
    992: {
      nav: true,
    },
  },
};

export const topGuideCarouselConfig = {
  loop: true,
  autoplay: true,
  smartSpeed: 850,
  margin: 10,
  autoplayHoverPause: true,
  autoplayTimeout: 4000,
  nav: true,
  items: 7,
  dots: false,
  navText: [
    '<i class="fa-solid fa-chevron-left"></i>',
    '<i class="fa-solid fa-chevron-right"></i>',
  ],
  responsive: {
    0: {
      items: 1,
    },
    425: {
      items: 2,
    },
    768: {
      items: 4,
    },
    1200: {
      items: 7,
    },
  },
};

export const detailMomentCarouselConfig = {
  loop: true,
  autoplay: true,
  smartSpeed: 850,
  autoplayHoverPause: true,
  autoplayTimeout: 4500,
  margin: 20,
  navText: [
    '<i class="fa-solid fa-chevron-left"></i>',
    '<i class="fa-solid fa-chevron-right"></i>',
  ],
  responsive: {
    0: {
      margin: 15,
      items: 1,
    },
    600: {
      items: 2,
    },
    1024: {
      item: 3,
    },
    1280: {
      items: 3,
      nav: true,
      dots: false,
    },
  },
};

export const topDestinationsConfig = {
  loop: true,
  autoplay: true,
  smartSpeed: 850,
  autoplayHoverPause: true,
  autoplayTimeout: 4500,
  margin: 20,
  navText: [
    '<i class="fa-solid fa-chevron-left"></i>',
    '<i class="fa-solid fa-chevron-right"></i>',
  ],
  responsive: {
    0: {
      margin: 15,
      items: 1,
    },
    600: {
      items: 2,
    },
    1024: {
      item: 3,
    },
    1280: {
      items: 4,
      nav: true,
      dots: false,
    },
  },
};
