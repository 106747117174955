function setupTabClicks(tabItemSelector, tabPaneSelector, tabID, activateImage) {
  let isScrolling = false;

  // Hàm xử lý sự kiện
  function handleTabClick(e) {
    var tabTarget = $(this).data("tab");
    var parentTab = $(this).closest(tabID);

    if (parentTab.length === 0) {
      return; // Nếu không tìm thấy parentTabId, dừng lại
    }

    var parentTabId = parentTab.attr("id");

    $("#" + parentTabId + " " + tabItemSelector).removeClass("active");
    $("#" + parentTabId + " " + tabPaneSelector).removeClass("active");

    $(this).addClass("active");
    
    $("#" + parentTabId + " " + tabPaneSelector + "[data-tab='" + tabTarget + "']").addClass("active");

    if (activateImage) {
      $("#" + parentTabId + " " + tabItemSelector + " img").each(function() {
        var imgSrc = $(this).attr('src').replace('-active', '');
        $(this).attr('src', imgSrc);
      });

      var activeImg = $(this).find('img');
      var activeImgSrc = activeImg.attr('src').replace('.svg', '-active.svg');
      activeImg.attr('src', activeImgSrc);
    }

    $("#" + parentTabId + " " + tabPaneSelector + "[data-tab='" + tabTarget + "'] .owl-carousel").trigger("refresh.owl.carousel");
  }

  // Lắng nghe sự kiện click
  $(tabID).on('click', tabItemSelector, function (e) {
    if (e.handled !== true) {
      handleTabClick.call(this, e);
      e.handled = true; // Đánh dấu sự kiện đã được xử lý
    }
  });

  // Lắng nghe sự kiện touchstart để theo dõi bắt đầu cuộn
  $(tabID).on('touchstart', tabItemSelector, function (e) {
    isScrolling = false; // Reset cờ khi bắt đầu chạm
  });

  // Lắng nghe sự kiện touchmove để phát hiện cuộn
  $(tabID).on('touchmove', tabItemSelector, function (e) {
    isScrolling = true; // Đặt cờ khi có cuộn
  });

  // Lắng nghe sự kiện touchend
  $(tabID).on('touchend', tabItemSelector, function (e) {
    if (!isScrolling && e.handled !== true) {
      handleTabClick.call(this, e);
      e.handled = true; // Đánh dấu sự kiện đã được xử lý
    }
  });
}

export default setupTabClicks;