export function setupRoomQuantityHandlers() {
   $(".filter-room-item-quantity button").click(function () {
     var $input = $(this).siblings("input");
     var count = parseInt($input.val(), 10);
     if ($(this).find("img").attr("alt") === "this-icon") {
       if ($(this).find("img").attr("src").includes("plus")) {
         $input.val(count + 1);
       } else if ($(this).find("img").attr("src").includes("minus")) {
         $input.val(count - 1 < 0 ? 0 : count - 1);
       }
       updateRoomInfo();
     }
   });
 
   function updateRoomInfo() {
     var roomCount = $("#roomCount").val();
     var adultCount = $("#adultCount").val();
     var childrenCount = $("#childrenCount").val();
     var infoText = `${adultCount} người lớn, ${childrenCount} trẻ em, ${roomCount} phòng`;
     $("#roomCountContent").text(infoText);
   }
 }
 