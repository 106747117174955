export function initDropdown() {
  $(".search-page-sort-button").click(function (event) {
    $(this).find(".search-page-sort-dropdown").toggleClass("active");
    event.stopPropagation();
  });

  $(".search-page-sort-dropdown li").click(function (event) {
    $(this)
      .closest(".search-page-sort-button")
      .find(".search-page-sort-main")
      .text($(this).text());
    $(".search-page-sort-dropdown").removeClass("active");
    event.stopPropagation();
  });

  $(document).click(function () {
    $(".search-page-sort-dropdown").removeClass("active");
  });
}
