function setupFilterTabs() {
   $(".filter-tab-item").click(function () {
     var tabId = $(this).attr("data-tab");
 
     // Remove active class from all tabs and panes
     $(".filter-tab-item, .filter-pane").removeClass("active");
 
     // Reset the image sources for all tabs
     $(".filter-tab-item").each(function () {
       var img = $(this).find("img");
       var src = img.attr("src");
       img.attr("src", src.replace("-active", ""));
     });
 
     // Activate the clicked tab and corresponding pane
     var img = $(this).find("img");
     var src = img.attr("src");
     img.attr("src", src.replace(".svg", "-active.svg"));
 
     $(this).addClass("active");
     $('.filter-pane[data-tab="' + tabId + '"]').addClass("active");
   });
 }
 
 export default setupFilterTabs;