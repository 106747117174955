export function setupRoomCount() {
    const $spanElement = $("#dropdown-guide-selectRoom span");
    const defaultText = "Chọn Số lượng";
    $spanElement.text(defaultText);

    const updateRoomCount = () => {
      const adultCount = parseInt($("#adultCount-guide").val(), 10);
      const childrenCount = parseInt($("#childrenCount-guide").val(), 10);

      $spanElement.text(`${adultCount} người lớn - ${childrenCount} trẻ em`);
    };

    $(".dropdown-guide-room-item-quantity button").on("click", function() {
      const $input = $(this).siblings("input");
      let value = parseInt($input.val(), 10);

      if ($(this).find("img").attr("src").includes("minus-icon")) {
        if (value > 0) {
          value -= 1;
        }
      } else {
        value += 1;
      }

      $input.val(value);
      updateRoomCount();
    });

    // Initial call to set the initial value
    $spanElement.text(defaultText);
}
