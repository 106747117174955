export function setupServicesControl() {
  // Hide all services-control items initially
  $(".services-item").click(function () {
    // Hide all services-control items
    $(".services-control").slideUp();

    // Remove active class from all services-item
    $(".services-item").removeClass("active");

    // Add active class to the clicked services-item
    $(this).addClass("active");

    // Show the corresponding services-control item
    $(this).next(".services-control").slideDown();
  });
}
