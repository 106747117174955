export function setupDropdownHandlers() {
  $(".filter-room").on("click", event => event.stopPropagation());

  $(document).on("click", function (e) {
    if (!$(e.target).closest(".filter-select").length) {
      $(".filter-dropdown, .filter-room").removeClass("active");
    } else {
      const $clickedFilterSelect = $(e.target).closest(".filter-select");
      $(".filter-select").not($clickedFilterSelect).find(".filter-dropdown, .filter-room").removeClass("active");
    }
  });

  $(".filter-select").on("click", function () {
    const $this = $(this);
    const dropdownsList = ["[data-filter=location]", "[data-filter=language]", "[data-filter=bookRoom]"];
    dropdownsList.forEach(dropdownStr => {
      const dropdown = $this.find(dropdownStr);
      dropdown.toggleClass("active");

      if (dropdown.is(":visible")) {
        const inputField = dropdown.find("input[placeholder='Tìm kiếm']");
        if (!inputField.val()) {
          inputField.focus();
        }
        if (!inputField.data("input-initialized")) {
          inputField.on("input", handleInput).data("input-initialized", true);
        }
      }
    });

    function handleInput() {
      const inputValue = $(this).val().trim();
      $this.find("span").first().text(inputValue || "Địa điểm");
    }
  });

  $(".filter-select ul li").on("click", function () {
    var selectedValue = $(this).text();
    $(this).closest(".filter-select").find("span").first().text(selectedValue);
  });

  $(".filter-select input[placeholder='Tìm kiếm']").on("click", function (event) {
    event.stopPropagation();
    const inputValue = $(this).val().trim();
    if (inputValue) {
      $(this).closest(".filter-select").find("span").first().text(inputValue);
    }
  });
}
