export function dropdownRoom(toggleSelector, dropdownSelector) {
   $(toggleSelector).on("click", function () {
     $(dropdownSelector)
       .toggleClass("active")
       .click(function (event) {
         event.stopPropagation();
       });
   });
 
   $(document).on("click", function (event) {
     if (!$(event.target).closest(toggleSelector).length) {
       $(dropdownSelector).removeClass("active");
     }
   });
 }
 