export function initScrollManager() {
   var headerHeight = $(".header").innerHeight();
   var backToTop = $("#back-to-top");
 
   function debounce(func, wait, immediate) {
     var timeout;
     return function() {
       var context = this,
         args = arguments;
       clearTimeout(timeout);
       timeout = setTimeout(function() {
         timeout = null;
         if (!immediate) func.apply(context, args);
       }, wait);
       if (immediate && !timeout) func.apply(context, args);
     };
   }
 
   const handleScroll = debounce(function() {
     var scrollTop = $(window).scrollTop();
 
     // Xử lý hiển thị nút back-to-top
     if (scrollTop > 450) {
       backToTop.fadeIn().css("transform", "translateY(0)");
     } else {
       backToTop.fadeOut().css("transform", "translateY(100%)");
     }
 
     // Xử lý thêm/gỡ class 'fixed' cho header và điều chỉnh padding của body
     if (scrollTop > 450) {
       $(".header").addClass("fixed").find(".logo").css("max-width", "160px");
       $("body").css("padding-top", headerHeight + "px");
     } else {
       $(".header").removeClass("fixed").find(".logo").css("max-width", "");
       $("body").css("padding-top", "0");
     }
   }, 50);
 
   $(window).scroll(handleScroll);
 }
 