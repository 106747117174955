function setupMobileMenu() {
   var overlayMenu = $(".overlay-menu");
   var menuMobile = $(".menu-mobile");
 
   $(".header-wrapper-bars").click(function (e) {
     menuMobile.addClass("active");
     overlayMenu.fadeIn();
     $("body").addClass("no-scroll");
 
     // Unbind previous click handlers to avoid multiple triggers
     $(".overlay-menu, .menu-mobile-close").off("click");
 
     setTimeout(() => {
       $(".overlay-menu, .menu-mobile-close").click(function (e) {
         menuMobile.removeClass("active");
         overlayMenu.fadeOut();
         $("body").removeClass("no-scroll");
       });
     }, 660); // Ensure handlers are bound after animations are complete
   });
 }
 
 export default setupMobileMenu;
 