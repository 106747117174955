export function TourGuideFilter() {
   const defaultValue = "Địa điểm";
 
   // Chức năng dropdown filter
   $(".tour-guide-filter-select").on("click", function (e) {
     // Close all other dropdowns
     $(".tour-guide-filter-dropdown").removeClass("active");
 
     const $this = $(this);
     const dataName = $this.find(".tour-guide-filter-dropdown").attr("data-filter");
     const $dropdown = $(".tour-guide-filter-dropdown[data-filter=" + dataName + "]");
 
     $dropdown.toggleClass("active");
     $dropdown.find("input[type='text']").focus();
 
     e.stopPropagation();
   });
 
   $(document).on("click", function (e) {
     const $target = $(e.target);
     if (!$target.closest(".tour-guide-filter-select").length) {
       $(".tour-guide-filter-dropdown").removeClass("active");
     }
   });
 
   $(".tour-guide-filter-dropdown ul li").on("click", function (e) {
     const $this = $(this);
     const selectedValue = $this.text();
     const $dropdown = $this.closest(".tour-guide-filter-dropdown");
     const dataFilter = $dropdown.attr("data-filter");
 
     // Find the corresponding span to update its text based on data-filter
     if (dataFilter === "location") {
       $("#search-value").text(selectedValue);
     } else if (dataFilter === "language") {
       // Assuming you have another span for language filter value
       $("#language-value").text(selectedValue);
     }
     
     $dropdown.removeClass("active");
     e.stopPropagation();
   });
 
   $(".tour-guide-filter-dropdown input[type='text']").on("input click", function (e) {
     const $this = $(this);
     const inputValue = $this.val().trim();
     const $dropdown = $this.closest(".tour-guide-filter-dropdown");
     const dataFilter = $dropdown.attr("data-filter");
 
     if (inputValue) {
       if (dataFilter === "location") {
         $("#search-value").text(inputValue);
       } else if (dataFilter === "language") {
         $("#language-value").text(inputValue);
       }
     } else {
       if (dataFilter === "location") {
         $("#search-value").text(defaultValue);
       } else if (dataFilter === "language") {
         $("#language-value").text("Chọn ngôn ngữ dành cho hướng dẫn viên");
       }
     }
 
     e.stopPropagation();
   });
 }
 