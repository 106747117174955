export function initFancyBox() {
  const $carouselElement = $("#myCarousel");
  if ($carouselElement.length) {
    const carouselInstance = new Carousel(
      $carouselElement[0],
      {
        Dots: false,
        Navigation: false, 
        Thumbs: {
          type: "classic",
        },
      },
      { Thumbs }
    );

    $(".carousel-button-prev").on("click", function() {
      carouselInstance.slidePrev();
    });

    $(".carousel-button-next").on("click", function() {
      carouselInstance.slideNext();
    });
  } else {
    console.warn('Element with ID "myCarousel" not found.');
  }
}
